/* eslint-disable unicorn/filename-case */
'use client';

import NextError from 'next/error';
import { useEffect } from 'react';

import { Dependencies } from '@/dependencies';

const reportError = async (error: Error & { digest?: string }) => {
  const errorService = await Dependencies.errorService;
  await errorService.capture(error);
};

// Via https://docs.sentry.io/platforms/javascript/guides/nextjs/manual-setup/#react-render-errors-in-app-router
export default function GlobalError({
  error,
}: {
  error: Error & { digest?: string };
}) {
  useEffect(() => {
    reportError(error);
  }, [error]);

  return (
    <html>
      <body>
        {/* `NextError` is the default Next.js error page component. Its type
        definition requires a `statusCode` prop. However, since the App Router
        does not expose status codes for errors, we simply pass 0 to render a
        generic error message. */}
        <NextError statusCode={0} />
      </body>
    </html>
  );
}
